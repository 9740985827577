@media screen and (max-width: 480px){

    .js-masonry[data-columns]::before {
        content: '1 .o-grid__cell.u-1of1--sm';
    }
}

@media screen and (min-width: 768px) {

    .js-masonry[data-columns]::before {
        content: '2 .o-grid__cell.u-1of2--md';
    }
}
