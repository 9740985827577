.c-about {
    @include rhythm-padding(2);
    background-color: $color-gray-lightest;
}

.c-contact {
    padding-bottom: rhythm(3);
    background-color: $color-gray-lightest;

    .c-btn {
        margin-top: rhythm(1);
    }
}

.c-work {
    @include rhythm-padding(4, 3);
    position: relative;
    background-color: $color-gray-lightest;

    &::before {
        @include offset(absolute, -20px, null, null, 50%);
        @include size(66px, 78px);
        margin-left: -33px;
        background-position: -96px 0;
        content: '';
        z-index: $layer-1;
    }

    .c-btn {
        margin-right: auto;
        margin-left: auto;
    }

    @include breakpoint(large) {

        @include rhythm-padding(4);

        &::after {
            @include offset(absolute, 0, null, null, 50%);
            @include size(20px, 100%);
            margin-left: -10px;
            background-color: $color-primary;
            content: '';
        }

        .js-masonry > .o-grid__cell {

            @include even() {
                margin-top: rhythm(3);
            }
        }

        .c-btn {
            @include offset(absolute, null, null, rhythm(1), 50%);
            transform: translateX(-50%);
            z-index: $layer-1;
        }
    }
}


.c-logo-bar {
    @include rhythm-padding(2);
    text-align: center;

    img {
        width: 100%;
        margin-bottom: rhythm(2);
        padding-right: rhythm(3);
        padding-left: rhythm(3);

        @include breakpoint(medium) {
            margin-bottom: 0;
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.c-clients {
    padding-bottom: rhythm(1.5);

    .o-grid__cell {
        margin-top: rhythm(1.5);
    }
}

.c-404 {
    @include rhythm-padding(4, 6);
    text-align: center;

    h1 {
        font-size: 6rem;
        font-weight: 800;
    }

    p {
        @include rhythm-padding(1);
    }

    .c-btn {
        margin-top: rhythm(1);
        margin-right: auto;
        margin-left: auto;
    }
}
