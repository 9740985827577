.c-panorama {

    &::before {
        @include offset(absolute, 0, null, null, 0);
        @include square(100%);
        background-color: rgba($color-third, .82);
        content: '';
        z-index: $layer-1;
    }

    .o-panorama__content {
        @include rhythm-padding(8);
        position: relative;
        color: $color-white;
        text-align: center;
        z-index: $layer-1;
    }

    @include breakpoint(large) {

        .o-panorama__content {
            @include rhythm-padding(8);
        }
    }
}


.c-panorama--large {

    .o-panorama__content {
        max-width: 50rem;
        margin-right: auto;
        margin-left: auto;

        .o-panorama__heading {
            @include responsive-text-size('tera');
            font-family: $secondary-font;
            font-weight: 900;
        }

        .o-panorama__subtitle {
            @include responsive-text-size('alpha');
            display: block;
            margin-top: rhythm(1);
            font-family: $secondary-font;
            font-weight: 100;
        }
    }
}

.c-panorama--small {

    .o-panorama__content {
        @include rhythm-padding(3.5);

        .o-panorama__heading {
            @include responsive-text-size('giga');
            display: block;
            max-width: $text-max-width;
            margin-right: auto;
            margin-left: auto;
            font-family: $primary-font;
        }
    }

    @include breakpoint(large) {

        .o-panorama__content {
            @include rhythm-padding(3.5);
        }
    }
}

.c-panorama--logo {
    position: relative;

    @include breakpoint(large) {
        margin-bottom: rhythm(2);

        &::after {
            @include offset(absolute, null, null, -54px, 50%);
            @include size(92px, 108px);
            margin-left: -46px;
            background-position: 0 0;
            content: '';
            z-index: $layer-1;
        }
    }
}

.c-panorama--logo-404 {
    @include breakpoint(large) {

        &::after {
            @include size(194px, 138px);
            @include size(194px, 138px);
            margin-left: -97px;
            background-position: 0 -384px;
            content: '';
            z-index: $layer-1;
        }
    }
}

.c-panorama--contact {
    background-image: img-url('../../wp-content/themes/harms-communicatie/assets/images/contact-image-lg.jpg');
    background-position: center center;
    background-size: cover;

    @include breakpoint(large) {
        padding: rhythm(3);

        .o-panorama__content {
            background-color: $color-white;
        }

        #map {
            height: 100px;
        }
    }
}

.c-panorama--twitter {

    .o-panorama__content {
        @include rhythm-padding(4);
        max-width: 800px;
        margin-right: auto;
        margin-left: auto;
        text-align: left;

        .c-twitter__name {
            @include responsive-text-size('zeta');
            display: block;
        }

        p {
            @include responsive-text-size('mega');
            margin-top: rhythm(.5);
        }
    }

    .c-socials {
        margin-top: rhythm(2);
        text-align: center;

        .c-social {
            margin-right: rhythm(.5);
        }
    }
}

.c-panorama--footer {

    .o-panorama__content {
        @include rhythm-padding(4);
    }

    .c-footer__header {
        font-family: $primary-font;
        text-align: center;
    }

    .o-list-plain {

        text-align: center;

        li {
            margin-bottom: rhythm(.5);
        }
    }

    @include breakpoint(large) {

        .o-panorama__content {
            @include rhythm-padding(5);
        }

        .c-footer__header {
            text-align: left;
        }

        .o-list-plain {

            text-align: left;
        }
    }
}

.js-image-liquid {
    transition: opacity .14s ease-in;
    opacity: 0;
}

.c-panorama--home {

    @include breakpoint(large) {
        width: 100vw;
        height: 100vh;

        .o-panorama__content {
            @include offset(absolute, 50%, null, null, 50%);
            transform: translate(-50%, -50%);
        }
    }
}
