/*----------------------------------------------------------------------------*\
    #COMPONENTS NAVIGATION
\*----------------------------------------------------------------------------*/

.c-nav-main__list {
    @include pullviewport($contain-percentage);
    height: 100vh;
    max-height: 0;
    padding-top: 0;
    transition: max-height .24s ease-in, padding-top .24s ease-in;
    background-color: rgba(3, 28, 34, .9);
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;

    li {
        @include offset(relative, -20px, null, null, null);
        padding: rhythm(1) rhythm(.75);
        transition: opacity .24s .24s ease-in, top .24s .24s ease-in, color .24s ease-in;
        color: $color-white;
        font-family: $primary-font;
        font-size: 26px;
        font-weight: 800;
        opacity: 0;

        &.current-menu-item {
            color: $color-secondary;
        }

        &:hover {
            color: $color-secondary;

            &::after {
                right: 0;
                bottom: 24px;
                left: 0;
                opacity: 1;
            }
        }

        &::before {
            display: none;
        }
    }

    &.is-open {
        max-height: 70rem;
        padding-top: rhythm(4.5);

        li {
            top: 0;
            opacity: 1;
        }
    }

    @include breakpoint(large) {
        @include pullviewport(0);
        height: auto;
        max-height: 70rem;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
        text-align: right;

        li {
            @include responsive-text-size('nano');
            display: inline-block;
            top: 0;
            padding: rhythm(1.25) rhythm(.75);
            font-weight: 700;
            opacity: 1;

            &::after {
                @include offset(absolute, null, 50%, 32px, 50%);
                height: 1px;
                transition-duration: .24s;
                transition-property: right, bottom, left, opacity;
                transition-timing-function: ease-in;
                background-color: $color-white;
                content: '';
                opacity: 0;
            }
        }
    }
}

// .c-nav-main__item { }

// .c-nav-main__link { }
