/*----------------------------------------------------------------------------*\
    #COMPONENTS MASTHEAD
\*----------------------------------------------------------------------------*/

.c-masthead {
    @include size(150px, 50px);
    display: block;
    float: left;

    @include breakpoint(large) {
        @include size(236px, 66px);
    }
}
