/*----------------------------------------------------------------------------*\
    #SETTIGNS Z-INDICES
\*----------------------------------------------------------------------------*/

$hidden:                -1;
$layer-1:                100;
$layer-2:                200;
$layer-3:                300;
$layer-4:                400;
$layer-5:                500;
$layer-6:                600;
$layer-7:                700;
$layer-8:                800;
$layer-9:                900;
$layer-10:               1000;
